<template>
  <figure
    class="text-sm text-vdv-border-dark"
    :class="props.classes"
    style="height: auto !important"
  >
    <ImageBasic :image-data="imageData" :classes="props.imgClasses" />
    <template v-if="showMeta">
      <figcaption v-if="image.copyright == null" class="mt-xs">
        <b>{{ image.title }}</b>
      </figcaption>
      <figcaption v-else class="mt-xs">
        <i>
          <b>{{ image.title }} </b>
        </i>
        - &#169; {{ image.copyright }}
      </figcaption>
      <div v-show="image.shortDescription != null && isMobile === false">
        <i>{{ image.shortDescription }}</i>
      </div>
    </template>
  </figure>
</template>

<script setup lang="ts">
import ImageBasic from '@/components/components/core/basicImage/image-basic.vue';
import { mq_breakpointIsMobile } from '@/injectionSymbols';
import type { BasicImageData } from '../../core/basicImage/type';

const props = defineProps({
  imageData: {
    type: Object as PropType<BasicImageData>,
    required: false,
    default: null,
  },
  classes: {
    type: String,
    required: false,
    default: '',
  },
  imgClasses: {
    type: String,
    required: false,
    default: '',
  },
  showMeta: {
    type: Boolean,
    default: true,
  },
});

const isMobile = inject(mq_breakpointIsMobile);
const image = computed(() => {
  return props.imageData[isMobile.value ? 'mobile' : 'desktop'];
});
</script>
